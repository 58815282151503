import {
  subscribeToStatus,
  subscribeToTraffic,
  subscribeToSensor,
  subscribeToVehicleSubscription,
} from '@/graphql/subscriptions'
import {
  Traffic,
  Status,
  Sensor,
  SubscribeToStatusSubscription,
  SubscribeToTrafficSubscription,
  SubscribeToSensorSubscription,
  SubscribeToVehicleSubscriptionSubscription,
} from '@/API'
import { Observable } from '@/../node_modules/zen-observable-ts'
import vehicle from '@/types/vehicle'
import sensor from '@/types/sensor'
import sensorAlert from '@/types/sensorAlert'
import SensorModel from '@/services/models/Sensor'
import { workStatus } from '@/services/const'
import subscribeToVehicleResponse from '@/types/responses/subscribeToVehicleResponse'
import subscribeToSensorResponse from '@/types/responses/subscribeToSensorResponse'
import SubscribeProvider from '@/services/subscribe/providers/SubscribeProvider'
import queryService from '@/services/query/QueryServiceFabrick'
import AppSyncClient from '@/services/query/providers/Connection'
import gql from 'graphql-tag'
import Push from 'push.js'
import i18n from '@/lang/i18n'

import StatusModel from '@/services/models/Status'
import VehicleWorkStatus from '@/services/vehicle/VehicleWorkStatus'

import SensorAlertNotification from '@/services/models/Sensor/AlertNotification'
import SensorRecoveredNotification from '@/services/models/Sensor/RecoveredNotification'

type TrafficSubscriptionEvent = {
  data: SubscribeToTrafficSubscription
}

type StatusSubscriptionEvent = {
  data: SubscribeToStatusSubscription
}

type SensorSubscriptionEvent = {
  data: SubscribeToSensorSubscription
}

type VehicleSubscriptionsSubscriptionEvent = {
  data: SubscribeToVehicleSubscriptionSubscription
}

const sensorAlertNotification = new SensorAlertNotification('alert')
const sensorErrorNotification = new SensorAlertNotification('error')
const sensorAlertRecoveredNotification = new SensorRecoveredNotification(
  'alert'
)
const sensorErrorRecoveredNotification = new SensorRecoveredNotification(
  'error'
)

class AppSyncProvider implements SubscribeProvider {
  private appSyncConnection = AppSyncClient.connection()

  public trafficAction = async (
    traffic: Traffic,
    vehicle: vehicle,
    time: number,
    cb: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void
  ) => {
    if (!traffic?.sn) return
    traffic.tm = time

    let latestDrivingTime: number =
      vehicle.latest_driving_time || traffic.tm * 1000
    let status = vehicle.status
    const trafficElement =
      queryService().convertTrafficToTrafficElement(traffic)
    if (status != workStatus.WORK_STATUS_NOTHING) {
      if (traffic.sp > 0) {
        latestDrivingTime = traffic.tm * 1000
        if (status == workStatus.WORK_STATUS_STOPPING) {
          status = workStatus.WORK_STATUS_IN_SERVICE
        }
      } else {
        const current_time = new Date(traffic.tm * 1000)
        status = StatusModel.investigateVehicleStatus(
          status,
          latestDrivingTime,
          latestDrivingTime,
          current_time
        )
      }
    }
    const { dgn, lat, lon, sp } = traffic
    const delivery_plan_id = vehicle.in_service ? dgn : vehicle.delivery_plan_id
    const latestStatus = StatusModel.convertWorkStatusToVehicleMapStatus(status)
    const data: subscribeToVehicleResponse = {
      delivery_plan_id: delivery_plan_id,
      status: latestStatus,
      position: {
        lat: Number(lat),
        lng: Number(lon),
      },
      speed: sp,
      latest_status_time: vehicle.latest_status_time,
      latest_driving_time: latestDrivingTime,
      in_service: !!vehicle.in_service,
      engine_on: true,
      is_loaded: vehicle.is_loaded,
    }
    cb(vehicle, data, traffic)
  }

  public statusAction = async (
    st: Status,
    vehicle: vehicle,
    time: number,
    cb: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void
  ) => {
    if (!st?.serial_no) return
    st.time = time

    let vehicleStatus = StatusModel.convertStatus(<Status>st)
    const isLoaded = AppSyncProvider.isCarLoaded(
      vehicle.is_loaded,
      vehicleStatus
    )
    let inService = vehicle.in_service
    if (vehicleStatus == workStatus.WORK_STATUS_RETURNING_WAREHOUSE) {
      inService = false
    }
    if (vehicleStatus == workStatus.WORK_STATUS_LEAVING_WAREHOUSE) {
      inService = true
    }
    vehicleStatus = StatusModel.convertStatus(<Status>st, inService)
    const latestStatus =
      StatusModel.convertWorkStatusToVehicleMapStatus(vehicleStatus)
    const data: subscribeToVehicleResponse = {
      delivery_plan_id: st.delivery_group_no,
      status: <workStatus>latestStatus,
      position: {
        lat: vehicle.position?.lat,
        lng: vehicle.position?.lng,
      },
      latest_status_time: new Date().getTime(),
      latest_driving_time: st.time * 1000,
      in_service: !!inService,
      engine_on: !!vehicle.engine_on,
      is_loaded: isLoaded,
    }
    cb(vehicle, data, st)
  }

  public sensorAction = async (
    sensor: Sensor,
    time: number,
    vehicle: vehicle
  ) => {
    if (!sensor?.sn) {
      return
    }
    sensor.tm = time

    sensorAlertRecoveredNotification.notify(vehicle, sensor)
    sensorErrorRecoveredNotification.notify(vehicle, sensor)
    sensorAlertNotification.notify(vehicle, sensor)
    sensorErrorNotification.notify(vehicle, sensor)
  }

  public switchAlertNotification(flag: boolean) {
    sensorAlertRecoveredNotification.setNotificationable(flag)
    sensorErrorRecoveredNotification.setNotificationable(flag)
    sensorAlertNotification.setNotificationable(flag)
    sensorErrorNotification.setNotificationable(flag)
  }

  private static isCarLoaded(
    currentIsLoadedValue: boolean,
    status: workStatus
  ): boolean {
    const vehicleWorkStatusEntity = new VehicleWorkStatus(status)
    const needToChangeValue =
      vehicleWorkStatusEntity.isEventRelatedToCarLoadEvent()
    if (needToChangeValue) {
      return vehicleWorkStatusEntity.isCarLoaded()
    }
    return currentIsLoadedValue
  }

  public subscribeToVehicleSubscriptions = (
    vehicle: vehicle,
    trafficAction: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void,
    statusAction: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void
  ) => {
    const subscription = this.appSyncConnection.subscribe({
      query: gql(subscribeToVehicleSubscription),
      variables: {
        serial_no: vehicle.device_id,
      },
    }) as Observable<object>

    return subscription.subscribe({
      next: (provider: VehicleSubscriptionsSubscriptionEvent) => {
        const topic =
          provider?.data?.subscribeToVehicleSubscription?.topic ?? ''
        const payload = JSON.parse(
          provider?.data?.subscribeToVehicleSubscription?.payload ?? '{}'
        )
        const time = provider?.data?.subscribeToVehicleSubscription?.tm ?? 0
        switch (true) {
          case /traffic/.test(topic):
            this.trafficAction(payload, vehicle, time, trafficAction)
            break
          case /status/.test(topic):
            this.statusAction(payload, vehicle, time, statusAction)
            break
          case /sensor/.test(topic):
            this.sensorAction(payload, time, vehicle)
            break
        }
      },
    })
  }
}

export default AppSyncProvider
